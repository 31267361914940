<template>
	<header class="header">
		<link
			rel="icon"
			type="image/png"
			sizes="32x32"
			:href="favicon"
		>
		<div class="columns is-mobile">
			<div class="column is-offset-1-tablet is-10-tablet is-paddingless">
				<div class="columns is-marginless">
					<div class="column is-narrow is-paddingless logo-wrapper">
						<router-link :to="{ name: 'home' }">
							<Logo maxwidth="156px" />
						</router-link>
					</div>
					<div class="column" />
					<div class="column is-narrow is-paddingless col-userarea">
						<router-link
							class="link-header is-hidden-mobile"
							:to="{ name: 'home' }"
						>
							Portal do Estudante
						</router-link>

						<b-dropdown
							position="is-bottom-left"
							aria-role="menu"
							class="link-servicos"
							:class="{ 'darkmode': isDarkMode }"
						>
							<template #trigger>
								<button class="btn-servicos">
									Serviços
									<b-icon
										icon="chevron-down"
										size="is-small"
									/>
								</button>
							</template>

							<b-dropdown-item
								v-for="(menuItem, index) in joinAcess"
								:key="index"
								:has-link="true"
							>
								<Anchor
									v-if="get(menuItem, 'visible', true)"
									class="dropdown-link link-servicos has-text-right"
									:href="get(menuItem, 'href', '/')"
									:target="get(menuItem, 'target', '_self')"
								>
									<Icon
										v-if="menuItemWarning(menuItem)"
										class="icon-warning"
										file="warning"
										size="1.5em"
									/>
									{{ get(menuItem, 'label', 'untitle') }}
								</Anchor>
							</b-dropdown-item>

							<b-dropdown-item :has-link="true">
								<Anchor
									v-if="permiteVisualizarExperiencias"
									class="dropdown-link link-servicos has-text-right"
									:href="
										permiteVisualizarExperiencias
											? get(getAcessoExperiencias, 'href', '/')
											: ''
									"
									:target="
										permiteVisualizarExperiencias
											? get(
												getAcessoExperiencias,
												'target',
												'_self'
											)
											: ''
									"
								>
									{{
										get(getAcessoExperiencias, 'label', 'untitle')
									}}
								</Anchor>
							</b-dropdown-item>

							<b-dropdown-item :has-link="true">
								<Anchor
									v-if="permiteVisualizarAtividadesComplementares"
									class="dropdown-link link-servicos has-text-right"
									:href="
										permiteAcessarAtividadesComplementares
											? get(getAcessoAtividadesComplementares, 'href', '/')
											: ''
									"
									:target="
										permiteAcessarAtividadesComplementares
											? get(
												getAcessoAtividadesComplementares,
												'target',
												'_self'
											)
											: ''
									"
									@click.native="verificaAcessarAtividadesComplementares"
								>
									{{
										get(getAcessoAtividadesComplementares, 'label', 'untitle')
									}}
								</Anchor>
							</b-dropdown-item>



							<b-dropdown-item :has-link="true">
								<Anchor
									v-if="permiteVisualizarAcessoCertificadoPosDigital"
									class="dropdown-link link-servicos has-text-right"
									:href="
										permiteAcessarCertificadoPosDigital
											? get(getAcessoCertificadoPosDigital, 'href', '/')
											: ''
									"
									:target="
										permiteAcessarCertificadoPosDigital
											? get(getAcessoCertificadoPosDigital, 'target', '_self')
											: ''
									"
									@click.native="verificaAcessarCertificadoPos"
								>
									{{ get(getAcessoCertificadoPosDigital, 'label', 'untitle') }}
								</Anchor>
							</b-dropdown-item>
						</b-dropdown>

						<a
							v-if="get($store, 'state.ie.links.central_ajuda.visible', false)"
							class="link-header"
							:href="get($store, 'state.ie.links.central_ajuda.href', '#')"
							:target="
								get($store, 'state.ie.links.central_ajuda.target', '_self')
							"
						>
							<span class="is-hidden-mobile">{{
								$store.state.ie.links.central_ajuda.label
							}}</span>
							<span
								class="is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only is-hidden-fullhd"
							>
								<b-icon icon="help-circle-outline" />
							</span>
						</a>

						<a
							v-if="
								$store.state.isPos
									&& this.$store.state.ie.sigla !== 'ebr'
									&& get($store, 'state.ie.links.central_pos.visible', false)
							"
							class="link-header"
							:href="get($store, 'state.ie.links.central_pos.href', '#')"
							:target="
								get($store, 'state.ie.links.central_pos.target', '_self')
							"
						>
							<span>{{
								$store.state.ie.links.central_pos.label
							}}</span>
						</a>

						<ProfileDropdown
							:has-new-notification="newNotificationQuantity > 0"
							@clickNotificationBtn="clickNotificationBtn"
						/>

						<b-dropdown
							position="is-bottom-left"
							aria-role="menu"
							class="notifications"
							:class="{ 'darkmode': isDarkMode }"
							:close-on-click="false"
							:trap-focus="true"
							:can-close="canCloseDropdownNotifications"
						>
							<b-loading
								v-model="dropdownLoading"
								class="py-3"
								:is-full-page="false"
								:can-cancel="false"
							/>

							<template #trigger>
								<button
									ref="ntfBtn"
									class="btn-notification"
								>
									<Icon
										file="bell"
										:size="20"
									/>
									<p
										v-if="newNotificationQuantity > 0"
										class="badge"
									>
										{{ newNotificationQuantity }}
									</p>
								</button>
							</template>

							<b-dropdown-item
								paddingless
								custom
							>
								<div class="notifications-wrapper">
									<div
										class="columns is-marginless is-mobile notifications-title"
									>
										<div class="column is-narrow">
											<h3 class="title is-5">
												Notificações
											</h3>
										</div>
										<div class="column has-text-right">
											<button
												v-if="newNotificationQuantity > 0 && !allNotifications"
												class="button btn-read-all"
												@click="readAllNotifications"
											>
												Marcar todas como lida
											</button>
										</div>
									</div>
									<div class="notifications-items-wrapper">
										<div class="notification-items">
											<div
												v-for="(notification, index) in notifications"
												:key="index"
												class="notification-item"
											>
												<Notification :data="notification" />
											</div>
										</div>
									</div>
								</div>
							</b-dropdown-item>

							<b-dropdown-item
								:has-link="false"
								class="btn-all-notifications"
								custom
								paddingless
							>
								<a
									class="dropdown-link has-text-centered"
									@click.prevent="allNotifications = !allNotifications"
								>
									{{
										allNotifications
											? 'Ver notificações não lidas'
											: 'Ver todas as notificações'
									}}
								</a>
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import Logo from '@components/Logo';
import Icon from '@components/Icon';
import Notification from '@components/notification/Notification';
import { get } from 'lodash';
import http from '@commons/http';

import { mapActions, mapGetters } from 'vuex';

import Anchor from './Anchor.vue';
import ProfileDropdown from '@components/ProfileDropdown.vue';

export default {
	name: 'Header',
	components: {
		Notification,
		Logo,
		Icon,
		Anchor,
		ProfileDropdown
	},
	data() {
		return {
			user: {
				image: ''
			},
			notificationsLimit: 10,
			notificationsOffset: 0,
			totalNotifications: 0,
			dropdownLoading: true,
			canCloseDropdownNotifications: true,
			bellNotifications: [],
			allNotifications: false,
			bellNotificationsIds: [1, 2],
			alertNotificationsIds: [3],
			popupNotificationsIds: [6],
			links: {
				userMenu: []
			},

			favicon: this.$store.state.ie.logo,
			loopUpdateData: null
		};
	},
	computed: {
		...mapGetters('servicos', [
			'getAcessos',
			'getAcessoUlife',
			'getAcessoDocumentos',
			'getAcessoPagamentos',
			'getAcessoCertificadoPosDigital',
			'getAcessoAtividadesComplementares',
			'getAcessoExperiencias',
			'contemServicos',
			'permiteVisualizarExperiencias',
			'permiteAcessarCertificadoPosDigital',
			'permiteVisualizarAcessoCertificadoPosDigital',
			'permiteAcessarAtividadesComplementares',
			'permiteVisualizarAtividadesComplementares'
		]),

		isDarkMode() {
			return this.$store.state.ie.sigla === 'ebr';
		},

		notifications() {
			return this.bellNotifications.filter(notification => {
				return this.bellNotificationsIds.includes(notification.tipo);
			});
		},
		menuItemWarning() {
			return menuItem =>
				(menuItem.href == 'envio-documentos' &&
					(this.$store.getters['documentos/getNaoEnviados'].length > 0 ||
						this.$store.getters['documentos/getInvalidados'].length > 0)) ||
				(menuItem.href == 'meus-pagamentos' &&
					this.$store.getters['pagamentos/getAtrasados'].length > 0);
		},
		newNotificationQuantity() {
			if (this.allNotifications) {
				return;
			}

			return this.bellNotifications.filter(
				notification => notification.lida == false
			).length;
		},
		joinAcess() {
			if (!this.contemServicos) {
				return [];
			}

			let joinAcessos = [];
			joinAcessos = joinAcessos.concat(this.getAcessos);
			joinAcessos.push(this.getAcessoPagamentos);
			joinAcessos.push(this.getAcessoDocumentos);
			joinAcessos.push(this.getAcessoUlife);

			return (
				joinAcessos.sort((a, b) => parseInt(a.ordem) - parseInt(b.ordem)) || []
			);
		}
	},
	watch: {
		allNotifications() {
			this.bellNotifications = [];
			this.getBellNotifications();
		}
	},
	async created() {
		this.dropdownActive = true;
		if (!this.$store.getters['sessao/isLogged']) {
			return;
		}

		this.$store.dispatch('ui/loading', true);
		await this.$store.dispatch('sessao/update');
		await Promise.all([
			this.getPopupNotification(),
			this.getAlertNotification(),
			this.getBellNotifications()
		]);
		this.$store.dispatch('ui/loading', false);

		if (!this.contemServicos) {
			this.todosServicos();
		}

		this.callUpdateData();
	},
	async beforeDestroy() {
		clearInterval(this.loopUpdateData);
	},
	methods: {
		...mapActions('servicos', ['todosServicos']),
		get,
		clickNotificationBtn() {
			const elem = this.$refs.ntfBtn;
			elem.click();
		},
		async getBellNotifications() {
			try {
				this.dropdownLoading = true;

				if (this.allNotifications) {
					await http
						.get(
							`/v1/aluno/notificacoes?tipo=${this.bellNotificationsIds.join(
								','
							)}`
						)
						.then(({ data }) => {
							let { notificacoes } = data;
							this.bellNotifications = notificacoes.filter(notificacao =>
								this.bellNotificationsIds.includes(notificacao.tipo)
							);
						});

					this.dropdownLoading = false;
					return;
				}

				await http
					.get(
						`/v1/aluno/notificacoes?tipo=${this.bellNotificationsIds.join(
							','
						)}&apenasNaoLidas=true`
					)
					.then(({ data }) => {
						let { notificacoes } = data;
						this.bellNotifications = notificacoes.filter(notificacao =>
							this.bellNotificationsIds.includes(notificacao.tipo)
						);
					});

				this.dropdownLoading = false;
			} catch (error) {
				this.dropdownLoading = false;

				this.$buefy.toast.open({
					message: 'Erro ao consultar notificações.',
					type: 'is-danger'
				});
			}
		},
		async getPopupNotification() {
			try {
				await http
					.get(
						`/v1/aluno/notificacoes?tipo=${this.popupNotificationsIds.join(
							','
						)}&apenasNaoLidas=true`
					)
					.then(({ data }) => {
						let { notificacoes } = data;

						const popupNotifications = notificacoes.filter(notificacao =>
							this.popupNotificationsIds.includes(notificacao.tipo)
						);

						this.$store.commit('setPopupNotifications', popupNotifications);
					});
			} catch (error) {
				this.$buefy.toast.open({
					message: 'Erro ao consultar notificações.',
					type: 'is-danger'
				});
			}
		},
		async getAlertNotification() {
			try {
				await http
					.get(
						`/v1/aluno/notificacoes?tipo=${this.alertNotificationsIds.join(
							','
						)}&apenasNaoLidas=true`
					)
					.then(({ data }) => {
						let { notificacoes } = data;

						const alertNotifications = notificacoes.filter(notificacao =>
							this.alertNotificationsIds.includes(notificacao.tipo)
						);

						this.$store.commit('setAlertNotifications', alertNotifications);
					});
			} catch (error) {
				this.$buefy.toast.open({
					message: 'Erro ao consultar notificações.',
					type: 'is-danger'
				});
			}
		},
		async readAllNotifications() {
			this.blockDropdownNotificationsClose();
			let notificationIds = this.bellNotifications
				.map(notification => {
					return notification.notu_id
						? `${notification.noti_id}-${notification.notu_id}`
						: `${notification.noti_id}-`;
				})
				.toString();

			this.dropdownLoading = true;

			await http
				.post(
					'/v1/aluno/notificacoes/marcar_lido',
					new URLSearchParams({ id: notificationIds }).toString()
				)
				.then(() => {
					this.bellNotifications = [];
					this.dropdownLoading = false;
				})
				.catch(() => {
					this.$buefy.toast.open({
						message: 'Erro ao enviar marcar todas como lidas.',
						type: 'is-danger'
					});

					this.dropdownLoading = false;
				});
		},
		blockDropdownNotificationsClose() {
			this.canCloseDropdownNotifications = false;

			setTimeout(() => {
				this.canCloseDropdownNotifications = true;
			}, 1000);
		},
		callUpdateData() {
			this.loopUpdateData = setInterval(() => {
				if (document.hasFocus()) {
					this.$store.dispatch('sessao/update');
				}
			}, 120000);
		},
		verificaAcessarCertificadoPos() {
			if (!this.permiteAcessarCertificadoPosDigital) {
				this.$buefy.snackbar.open({
					message:
						'Selecione um curso com status "Formado" no filtro de vinculo acima!',
					type: 'is-warning',
					position: 'is-bottom',
					actionText: null,
					indefinite: false,
					duration: 3500
				});
			}
		},

		verificaAcessarAtividadesComplementares() {
			if (!this.permiteAcessarAtividadesComplementares) {
				this.$buefy.snackbar.open({
					message:
						'Selecione um curso de graduação para acessar a página de Atividades Complementares!',
					type: 'is-warning',
					position: 'is-bottom',
					actionText: null,
					indefinite: false,
					duration: 3500
				});
			}
		}
	},
};
</script>
<style lang="scss">
.header {
    .logo-wrapper .ui-image img {
        background-position: 0 center;
        margin-left: 10px;
    }

    .dropdown-menu {
        min-width: 16rem;

		.dropdown-content {
			padding: 1rem;

			.has-link {
				text-align: left!important;

				.dropdown-link {
					text-align: left!important;

					&:hover {
						transition: 0.4s;
					}
				}
			}
		}

        .link-servicos & {
            min-width: 30rem;
        }
    }

    .dropdown.link-servicos {
        .dropdown-menu {
            min-width: 24rem;
            z-index: 40;
        }
    }

    .dropdown.notifications {
        .dropdown-menu {
            width: calc(100vw - 20px);
            max-width: 384px;
        }
    }

    @media (max-width: 768px) {
        .logo-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 70px;
            margin-top: 20px;
        }

        .dropdown.link-servicos .dropdown-menu {
            min-width: 12rem;
            width: 82%;
        }
    }
}
</style>

<style lang="scss" scoped>
@import '@styles/_variables';

.header {
    padding: 17px;
    background-color: $white; //IE
    background-color: var(--white);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px;

    .logo-wrapper {
        display: flex;
        align-items: center;
    }

    .col-userarea {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-notification {
        width: 34px;
        height: 34px;
        border-radius: 60px;
        border: 0 none;
        position: relative;
        background: transparent;
        cursor: pointer;
        margin: 0 8px;

		.custom-icon {
			::v-deep svg path {
				stroke: var(--primary);
			}
		}

        @media (max-width: 768px) {
            margin: 0 12px 0 0;
        }

        .image-icon {
            overflow: visible;
            width: 100%;
            height: auto;

            &:before {
                transition: all 200ms cubic-bezier(0.095, 0.31, 0.395, 1.44) 0ms;
                transform: scale(0);
                background-color: #37c36f;
                border-radius: 4px;
                position: absolute;
                content: '';
                width: 8px;
                height: 8px;
                z-index: 1;
                left: 16px;
                top: -1px;
            }
        }

        &.has-notification .image-icon:before {
            transform: scale(1);
        }
    }
	
    .btn-servicos {
        border: 0 none;
        background: transparent;
        cursor: pointer;
        font-weight: $font-weight-700;
        font-family: $nunito-sans;
        font-size: 14px;
        color: var(--primary);

        .icon {
            font-size: 20px;
            position: relative;
            top: 2px;
        }
    }

    .dropdown-content .dropdown-link {
        display: inline-block;
        font-weight: $font-weight-700; //IE
        font-weight: var(--font-weight-700);
        font-size: 14px;
    }

    .btn-all-notifications,
    .btn-other-action {
        background: #525252;
        text-align: right;
        border-radius: 0 0 6px 6px;
        padding: 0 !important;
        margin-top: 0.5rem;

        .dropdown-link {
            color: $white !important; //IE
            color: var(--white) !important;
            display: block;
        }
    }

    .link-header {
        font-weight: $font-weight-700; //IE
        font-weight: var(--font-weight-700);
        padding: 0 1.4rem;
        color: var(--text);
        font-size: 14px;

        @media (max-width: 768px) {
            padding: 0 0.4rem;
        }
    }

    .notifications, .link-servicos {
		&::v-deep.darkmode {
			& .dropdown-content {
				background-color: var(--charcoal-gray);
			}
			& .title {
				color: var(--primary-50)
			}
			& .dropdown-link:hover {
				background-color: var(--primary-700);
				color: var(--primary-50)!important;
			}
		}
        &-title {
            position: relative;
            top: -4px;
            padding-left: 0.6rem;
            padding-right: 0.6rem;
            padding-bottom: 0.6rem;
        }

        &-wrapper {
            .btn-read-all {
                padding: 0;
                max-width: initial;
                width: auto;
                margin: 0;
                font-size: 14px;
                border-color: #fff;
                position: relative;
                top: -4px;

                white-space: normal;
                line-height: 1.2;
            }
            .notifications-items-wrapper {
                max-height: 300px;

                @media (max-width: 768px) {
                    max-height: 80vh;
                }
                overflow-y: auto;
            }

            .notification-item {
                padding-bottom: 10px;
                margin-bottom: 10px;

                &:after {
                    content: '';
                    display: block;
                    width: calc(100% - 38px);
                    margin: 0 auto;
                    height: 1px;
                    background: #e0e0e0;
                    position: relative;
                    top: 10px;
                }

                &:last-of-type:after {
                    display: none;
                }
            }
        }
    }

    .badge {
        position: absolute;
        top: -4px;
        right: -1px;
        padding: 0px 5px;
        border-radius: 19px;
        background-color: var(--primary);
        color: white;
        font-size: 10px;
    }
}

// mobile only .
::v-deep .link-servicos .icon-warning {
    display: inline-block;

    svg {
        vertical-align: top;

        * {
            fill: #ff1515;
        }
    }
}
</style>
